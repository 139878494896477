import request from '@/utils/request'

//获取公司名称
export function getCompany(params) {
    return request({
        url: '/Home/GetPartnerList',
        method: 'get',
        params
    })
}

// 获取验证码
export function getCode(params) {
    return request({
        url: '/SSO/SendCode',
        method: 'post',
        params
    })
}
// 注册
export function setRegister(data) {
    return request({
        url: '/SSO/Register',
        method: 'post',
        data
    })
}
// 找回发送邮件
export function sendEmail(params) {
    return request({
        url: '/Personal/SendEmailPwd',
        method: 'get',
        params
    })
}
// 找回重置密码
export function resetPwd(params) {
    return request({
        url: '/Personal/Reset',
        method: 'get',
        params
    })
}

// 留资表单
export function AddUserRecord(data) {
    return request({
        url: '/UserRecordInfo/AddUserRecord',
        method: 'post',
        data
    })
}

// 留资表单-合作伙伴峰会
export function AddUserRecordPartnerSummit(data) {
    return request({
        url: '/UserRecordInfo/AddUserRecordPartnerSummit',
        method: 'post',
        data
    })
}

// 活动提报
export function GetUserRecordActivitySelect(params) {
    return request({
        url: '/Home/GetUserRecordActivitySelect',
        method: 'get',
        params
    })
}

// 留资表单
export function AddUserRecordAct(data) {
    return request({
        url: '/UserRecordInfo/AddUserRecordAct',
        method: 'post',
        data
    })
}

// 活动状态
export function CheckActStatus(params) {
    return request({
        url: '/UserRecordInfo/CheckActStatus',
        method: 'get',
        params
    })
}

// 论文征集页表单
export function SavePaper(data) {
    return request({
        url: '/Paper/SavePaper',
        method: 'post',
        data
    })
}